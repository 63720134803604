export class APIService {
  private static baseUrl = window.location.hostname.includes("localhost") ? "http://localhost:8888/api/v1/" : "https://starocean-api.leicht.io/api/v1/";

  private static request<T>(method: "GET" | "POST", pathname: string, body?: any, headers?: any): Promise<T> {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');

    const authorization = localStorage.getItem("Authorization")
    if (authorization) {
      requestHeaders.set('Authorization', authorization);
    }

    try {
      return fetch(this.baseUrl + pathname, {
        method: method,
        body: JSON.stringify(body),
        headers: requestHeaders
      })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json() as unknown as T
          } else {
            return new Promise((resolve, reject) => {
              reject(response.status)
            })
          }
        })
    } catch (exception) {
      return new Promise((resolve, reject) => {
        reject(exception)
      })
    }
  }

  public static async get<T>(pathname: string): Promise<T> {
    return this.request<T>("GET", pathname);
  }

  public static async post<T>(pathname: string, body?: any, headers?: any): Promise<T> {
    return this.request<T>("POST", pathname, body, headers);
  }

}